































































import Vue, { VueConstructor } from 'vue'
import CreditLine from '../../../../store/models/CreditLine'
import uiState from '../../../../ui-state'

import OtpModal from '@/components/OtpModal.vue'
import CreditLineOverview from '../../components/CreditLineOverview.vue'

export default (Vue as VueConstructor<
  Vue & { $refs: { otpModal: InstanceType<typeof OtpModal> } }
>).extend({
  components: {
    OtpModal,
    CreditLineOverview
  },

  data() {
    return {
      errorMessage: '',
      uiState: uiState([
        'idle',
        'fetching',
        'fetched',
        'validating',
        'error_validated',
        'request_otp',
        'submiting_otp',
        'error_otp',
        'validated',
        'low_virtual_account_balance'
      ])
    }
  },

  computed: {
    creditLineId(): string {
      return this.$route.params.creditLineId
    },

    creditLine(): CreditLine {
      return CreditLine.query()
        .with(['deadlines', 'organization', 'provisions'])
        .find(this.creditLineId) as CreditLine
    },

    loading(): boolean {
      return !this.creditLine || this.uiState.is('loading')
    },

    validating(): boolean {
      return this.uiState.is('validating')
    },

    submitingOtp(): boolean {
      return this.uiState.is('submiting_otp')
    },

    showAlreadyValidatedError(): boolean {
      return this.uiState.is('error_validated')
    },

    showLowVirtualBalanceAccount(): boolean {
      console.log('uiState', this.uiState.is('low_virtual_account_balance'))

      return this.uiState.is('low_virtual_account_balance')
    },

    showOtpModal(): boolean {
      return this.uiState.in(['request_otp', 'submiting_otp', 'error_otp'])
    },

    validated(): boolean {
      return this.uiState.is('validated')
    },

    validatedBy(): string {
      return this.uiState.data
        ? `${this.uiState.data.firstname} ${this.uiState.data.lastname}`
        : ''
    }
  },

  created() {
    this.uiState.set('loading')
    CreditLine.api()
      .fetchById(this.creditLineId)
      .then(() => {
        this.uiState.set('fetched')
      })
  },

  methods: {
    async onValidateClicked(otp?: string) {
      otp ? this.uiState.set('submiting_otp') : this.uiState.set('validating')

      try {
        await CreditLine.api().validate(this.creditLineId, otp)
        otp ? this.uiState.set('validated') : this.uiState.set('idle')
      } catch (error) {
        if (error.response) {
          switch (error.response.status) {
            case 409: // The credit line is already validated
              this.uiState.set(
                'error_validated',
                error.response.data.validatedBy
              )
              return
            case 403:
              this.uiState.set('request_otp')
              return
            case 400:
              this.uiState.set('error_otp')
              switch (error.response.data.code) {
                case 'otp_invalid':
                  this.$refs.otpModal.setError('Le code entré est invalide')
                  break
              }
              return
            case 422:
              switch (error.response.data.code) {
                case 'low_fnm_virtual_account_balance':
                  this.errorMessage = `Vous ne disposez pas de suffisamment de fonds sur votre compte ${error.response.data.data.otm} pour effectuer cette opération`
                  this.uiState.set('low_virtual_account_balance')
                  break
              }
          }
        }

        //this.uiState.set('idle')
      }
    }
  }
})
