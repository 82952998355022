


































































































































import moment from 'moment'
import Vue, { PropType } from 'vue'
import CreditLine from '../../../store/models/CreditLine'
import CreditLineDeadline from '../../../store/models/CreditLineDeadline'

export default Vue.extend({
  props: {
    creditLine: {
      type: Object as PropType<CreditLine>,
      required: true
    }
  },

  data() {
    return {
      otms: ['mtn', 'moov'],
      selectedOtm: 'mtn',
      columns: [
        { label: 'N°', field: '_index' },
        { label: 'Échéance', field: 'date' },
        { label: 'Capital', field: 'capital', currency: true },
        { label: 'Interêts', field: 'interest', currency: true },
        { label: 'Mensualité', field: 'monthlyAmount' }
      ]
    }
  },

  computed: {
    displayedDeadlined(): CreditLineDeadline[] {
      return this.creditLine.deadlines.filter(
        deadline => deadline.otm === this.selectedOtm
      )
    },

    totalCapital(): number {
      return this.displayedDeadlined.reduce(
        (total, item) => total + item.capital,
        0
      )
    },

    totalInterest(): number {
      return this.displayedDeadlined.reduce(
        (total, item) => total + item.interest,
        0
      )
    },

    totalMonthlyAmount(): number {
      return this.displayedDeadlined.reduce(
        (total, item) => total + item.monthlyAmount,
        0
      )
    },

    creditLineEffectDateFormat(this: any) {
      return moment(this.creditLine.effectDate).format('DD/MM/YYYY')
    }
  }
})
